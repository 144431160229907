
export type ActiveTenantId = string

// MAJOR - Get the active tenant ID.
// This is used throughout the app to determine which tenant the user is currently in the context of.
// This provides SSR-friendly access to the active tenant ID for the currently logged in admin user.
export const useActiveTenantId = () => {
    const activeTenantIdRef = useCookie<string>('wetix-active-tenant') // Default value is populted by the onIdTokenChanged handler in frontend/plugins/firebase.ts
    return activeTenantIdRef.value as ActiveTenantId // Return the active tenant ID
}

// Composable to switch to a different tenant.
// Primarily for use by superadmins!
export const useSwitchTenant = (newTenantId) => {
    // Set the tenant ID in cookie & store
    const cookie = useCookie('wetix-active-tenant');
    console.log(`Switching tenant from ${cookie} to: ${newTenantId}`)
    cookie.value = newTenantId;
    // Reload page
    return location.reload()
}